.recovery-form {
  &__title {
    display: block;
    color: $color-purple-600;
    font-weight: $large-weight;
    font-size: $x-medium;
    line-height: 3.2rem;
    margin-bottom: 3.2rem;
    text-align: center;
  }
  &__description {
    color: $color-purple-600;
    display: block;
    margin: -0.8rem 0 1.8rem 0;
    font-size: 1.4rem;
  }
  &__send-recovery-link-btn {
    box-shadow: none;
    margin-left: 1.6rem;
  }
  &__buttons {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.forgot-password-success {
  &__header {
    display: flex;
    text-align: center;
    font-size: $x-medium;
    font-weight: $large-weight;
    margin-bottom: 3.2rem;
    color: $color-purple-600;
    justify-content: center;
  }
  &__description {
    font-weight: $small-weight;
    font-size: $small;
    color: $color-purple-600;
    margin-bottom: 1.6rem;
    text-align: left;
    display: block;
  }

  &___return-to-signin {
    margin-left: 1.6rem;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 8.7rem;
  margin-top: 3.5rem;
}
