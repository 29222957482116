.settings-form {
  &__title {
    display: block;
    text-align: center;
    margin-bottom: 2.8rem;
    color: $color-purple-600;
    font-weight: $large-weight;
    font-size: $x-medium;
    line-height: 3.2rem;
  }
  &__description {
    color: $color-purple-600;
    font-weight: $small-weight;
    font-size: $small;
    display: block;
    margin-bottom: 4.1rem;
  }
  &__return-to-sign-in.btn {
    margin-left: 1.6rem;
  }
  &__buttons {
    margin-top: 3.2rem;
    display: flex;
    margin-left: 5.2rem;
    justify-content: space-between;
  }
}

.updated-password {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $xx-medium;
    font-weight: $large-weight;
    margin-bottom: 3.2rem;
    color: $color-purple-600;
  }
  &__title {
    margin-top: 1.4rem;
  }

  &__check {
    margin-top: 1.0rem;
    padding: 1.2rem;
    width: 5.2rem;
    height: 4.8rem;
    margin-right: 1.2rem;
    background-color: $super-white;
    border: 0.5rem solid;
    
  
    > svg {
      width: 3.1rem;
      height: 3.1rem;
    }
  }

  &__return-to-sign{
     margin-left: 11.6rem;
     margin-top: -2.0rem;

  }

  &__description {
    display: flex;
    justify-content: center;
    color: $color-purple-600;
    font-size: $small;
    font-weight: $small-weight;
    margin-bottom: 5.6rem;
    margin-top: -1.7rem;
  }
}
