@import '~@carecru/component-library/dist/scss/font.scss';
@import '~@carecru/component-library/dist/scss/app.scss';

#container {
  @import 'vars';
  @import '../views/view-styles';
  @import '../components/component-styles';
  
  * {
    font-family: $font-inter;
  }

  body {
    font-size: 10px;
  }

  .main-layout {
    #bg {
      background: url('./img/reskin-bg_wave.svg') no-repeat left top;
      background-size: cover;
      bottom: 0;
      display: block;
      height: 100vh;
      left: 0;
      overflow: hidden;
      position: fixed;
      right: 0;
      width: 100%;
      z-index: -5;
    }
    .bg-logo {
      margin: 20px 0 0 43px;
    }
    .main-layout__content {
      align-items: center;
      display: flex;
      height: 100vh;
      justify-content: center;
    }

    .card-box {
      background: $super-white;
      border-radius: 8px;
      box-shadow: $card-box-shadow;
      width: 433px;
      padding: 30px;
    }

    .card-content {
      .description {
        color: $color-purple-600;
        font-size: $small;
        letter-spacing: 0;
      }
      b {
        color: $color-purple-600;
        font-weight: $large-weight;
      }
    }
  }
}

.loading {
  padding: 0.625rem;
}

.alert-danger {
  font-size: small;
  .h4 {
    font-size: $font-size-base;
  }
}
