

.login-form {
  &__fields {
    margin-top: -1.0rem;
  }
  &__messages-container {
    margin-top: 1rem;
  }
  &__title {
    color: $color-purple-600;
    margin-bottom: 3.2rem;
    display: block;
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: 500;
    text-align: center;
  }
  &__buttons {
    margin-top: 3.8rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .btn {
      margin-left: 1.6rem;
    }
  }
}
