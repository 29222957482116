.field {
  &__group {
    position: relative;
  }
  &__error-msg {
    font-size: 1.2rem;
    line-height: 1.28rem;
    margin-top: 0.8rem;
    display: inline-block;

    &__password-validation {
      background: $super-white;
      border-radius: 0.4rem;
      width: 18rem;
      color: $color-purple-600;
      font-size: 1.2rem;
      padding: 1.6rem;
      position: absolute;
      right: -1.6rem;
      top: -2rem;
      transform: translateX(18rem);
      span {
        display: block;
        svg {
          color: $color-purple-300;
        }
        &.valid {
          svg {
            color: $color-purple-600;
          }
        }
        + span {
          margin-top: 0.8rem;
        }
      }
    }
  }
}

.updated-icon {
  position: absolute;
  right: 2.1rem;
  top: 4.1rem;
  z-index: 9999;
  color: $color-purple-300;
  font-size: 1.6rem;
  font-weight: 400;
}
