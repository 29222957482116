
.profile {
  &__avatar {
    height: auto;
    margin: 0.625em auto;
    min-width: 12.5em;
    width: 50%;
    img {
      max-width: 100%;
    }
  }
  &__row {
    align-items: center;
    display: flex;
  }
  &__prop {
      color: #154b6e;
      font-weight: bold;
      width: 6.25em;
    }
  &__value {
      text-align: left;
    }
  }
